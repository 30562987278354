import { StaticQuery, graphql } from 'gatsby'
import React from 'react'
import Img from 'gatsby-image'

import './bio.css'

const Bio = props => (
  <StaticQuery
    query={query}
    render={data => (
      <div className="body-wrapper">
        <div className="bio">
          {/* <div className="portrait">
            <Img alt="Portrait of Jim Feleen" fluid={data.portrait.childImageSharp.fluid}/>
          </div> */}
          <h2>About Jim</h2>
          <p>
            Claremont, NH Attorney James G. Feleen was born in Elmhurst, Illinois and admitted to the New Hampshire Bar in 1984. Attorney Feleen has extensive residential and commercial real estate experience. His practice is in Claremont NH where he lives with his wife, Ellie who teaches third grade at Maple Avenue School. They have three grown children – Ashley, Emily, and Michael.
          </p>
        </div>
        <Img className="family" alt="Feleen Family" fluid={data.family.childImageSharp.fluid}/>
      </div>
    )}
  />
);

const query = graphql`
  query {
    portrait: file(relativePath: { eq: "jim-feleen.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 250, quality: 90) {
            ...GatsbyImageSharpFluid
        }
      }
    }
    family: file(relativePath: { eq: "feleen-family.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 920, quality: 90) {
            ...GatsbyImageSharpFluid
        }
      }
    }


  }
`

export default Bio;
