import React from 'react'
import SEO from '../components/seo/seo'
import Experience from '../components/experience/experience'
import Bio from '../components/bio/bio'
import { IconContext } from "react-icons";
import { FaFacebookSquare, FaLinkedin  } from 'react-icons/fa'

const AboutPage = () => (
  <>
    <IconContext.Provider value={{ color: "var(--primary-color)", style: { verticalAlign: 'middle', fontSize: '3rem' }, className: "social-icons"}}>
    <SEO
      title="About"
      description="Lawyer Jim Feleen offers services as a real estate attorney, title lawyer, probate lawyer and more throughout Sullivan County, NH. 603-504-6507"
      keywords={['law', 'lawyer', 'law office', 'attorney', 'James Feleen', 'Jim Feleen', 'Claremont', 'NH', 'New Hampshire', 'Claremont NH', 'real estate', 'probate administration', 'probate', 'personal injury', 'injury', 'wills', 'estate planning', 'title company', 'general practice', 'accident', 'insurance claim', 'negligence', 'medical malpractice', 'work injuries']} />
    <Bio />
    <div className="clear"></div>
    <Experience>
    <h2>Education</h2>
      <div className="eds">
        <div className="ed">
          <h4>Bethel College, St. Paul MN</h4>
          <h5>B.A. 1980</h5>
        </div>
        <div className="ed">
          <h4>William Mitchell College of Law, St. Paul MN</h4>
          <h5>J.D. 1984</h5>
        </div>
      </div>
    </Experience>
    <Experience >
      <div className="memberships">
        <div className="member">
          <h2>Member</h2>
          <ul>
            <li>New Hampshire and Sullivan County Bar Associations</li>
            <li>Greater Claremont Chamber of Commerce</li>
            <li>Greater Claremont Board of Realtors</li>
            <li>Parks and Recreation Commission</li>
            <li>Board of Directors, Singing Hills Christian Fellowship</li>
            <li>Grace River Church</li>
            <li>NH Rail Trail Coalition</li>
          </ul>
        </div>
        <div className="former">
          <h2>Former Member</h2>
          <ul>
            <li>NH Bar Association Board of Governors</li>
            <li>Claremont School Board</li>
            <li>Goodwin Community Center Commission</li>
          </ul>
        </div>
      </div>
      </Experience>
      <Experience className="last">
        <h2>Connect On Social Media</h2>
        <div>
          <a href="https://www.facebook.com/Law-Office-of-James-G-Feleen-pllc-163317153825643/" target="_blank" rel="noopener noreferrer"><FaFacebookSquare /></a> <a href="https://www.linkedin.com/in/james-feleen-37782468/" target="_blank" rel="noopener noreferrer">< FaLinkedin /></a>
        </div>
      </Experience>
    </IconContext.Provider>
  </>
)

export default AboutPage
