import React from 'react'

import './experience.css'

const Experience = props => (
  <div className="body-wrapper">
    <div className={ `experience ${props.className}` }>
      {props.children}
    </div>
  </div>
);

export default Experience;
